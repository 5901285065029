
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import FormInput from '../../components/forms/Input.vue';
import FormSelect from '../../components/forms/Select.vue';
import FileDataHelper from '../../helpers/FileDataHelper';
import FinanceTypeDataHelper from '../../helpers/FinanceTypeDataHelper';
import ProductDataHelper from '../../helpers/ProductDataHelper';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';
import KYCDataService from '../../services/KYCDataService';
import LocationDataService from '../../services/LocationDataService';
import AccountDataService from '../../services/AccountDataService';

export default defineComponent({
  name: 'KYCProf',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormSelect,
    FormInput,
  },
  data() {
    return {
      cities: {
        list: {} as any,
      },
      form: {
        inputs: {
          fullname: {
            value: null,
            default: null,
            rule: 'required|regex_alpha',
            error: null,
          } as {[key: string]: any},
          address: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          addressPro: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          city: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          creation_date: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          date_start_activity: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          activity: {
            value: null,
            default: null,
            rule: 'required|regex_alpha',
            error: null,
          } as {[key: string]: any},
          identification_nif: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          identification_nis: {
            value: null,
            default: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          phone: {
            value: null,
            default: null,
            rule: 'required|phone:mobile,local',
            error: null,
          } as {[key: string]: any},
          fax: {
            value: null,
            default: null,
            rule: 'phone:local',
            error: null,
          } as {[key: string]: any},
          email: {
            value: null,
            default: null,
            rule: 'required|email',
            error: null,
          } as {[key: string]: any},
        } as {[key: string]: any},
      },
    };
  },
  created() {
    const vm = this;

    if (this.isCustomer()) {
      this.form.inputs.rib = {
        value: null,
        default: null,
        rule: 'required|rib:001',
        error: null,
      } as {[key: string]: any};
    }

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      if (this.isCustomer()) {
        this.form.inputs.rib = {
          value: null,
          default: null,
          rule: 'required|rib:001',
          error: null,
        } as {[key: string]: any};
      }

      vm.prefillWithLastKyc();

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);
      this.$store.dispatch('toggleTopBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      const token = this.getAccessToken();

      LocationDataService.getCities(token)
        .then((response: any) => {
          this.cities.list = {};
          const { data } = response.data;

          Object.keys(data).forEach((key: any) => {
            this.cities.list[data[key].pid as string] = data[key].name;
          });
        })
        .catch((e: Error) => { /* */ });

      FileDataHelper.prepareComponentData(
        this,
        this.$route.params.file as string,
        callback,
        (e: Error) => { /* */ },
      );
    },
    prefillWithLastKyc() {
      const vm = this;

      const token = this.getAccessToken();

      AccountDataService.getLastKyc(token, this.$store.state.selected.segment, this.$store.state.selected.financeType, this.$store.state.selected.product)
        .then((response: any) => {
          const { data } = response.data;

          Object.keys(data).forEach((key: string) => {
            if (key === 'rib') {
              if (vm.$store.state.selected.simulation.is_customer.value === 'yes') vm.form.inputs[key].default = data[key];
            } else if (['address', 'addressPro'].includes(key as string)) {
              vm.form.inputs[key].default = data[key].label;
              vm.form.inputs.city.default = data[key].city.pid;
            } else if (['creation_date', 'date_start_activity'].includes(key as string)) {
              vm.form.inputs[key].default = (new Date(data[key])).toISOString().substr(0, 10);
            } else vm.form.inputs[key].default = data[key];
          });
        })
        .catch((e: any) => { /* */ });
    },
    goToNextStep(event: any) {
      const vm = this;
      event.preventDefault();

      this.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          const token = this.getAccessToken();

          KYCDataService.createProf(token, this.$store.state.selected.file, {
            creation_date: this.form.inputs.creation_date.value,
            date_start_activity: this.form.inputs.date_start_activity.value,
            activity: this.form.inputs.activity.value,
            identification_nif: this.form.inputs.identification_nif.value,
            identification_nis: this.form.inputs.identification_nis.value,
            fullname: this.form.inputs.fullname.value,
            addressPro: this.form.inputs.addressPro.value,
            address: this.form.inputs.address.value,
            city: this.form.inputs.city.value,
            phone: this.form.inputs.phone.value,
            fax: this.form.inputs.fax.value,
            email: this.form.inputs.email.value,
            rib: this.isCustomer() ? this.form.inputs.rib.value : null,
            recaptcha: vm.$store.state.recaptcha,
          }).then((responses: any) => {
            vm.setStepAsCompleted(this, true);
          }).catch((e: any) => {
            this.$store.dispatch('isLoading', false);

            if (e.response.status === 422) {
              Object.keys(e.response.data.data.errors).forEach((name) => {
                [vm.form.inputs[name].error] = e.response.data.data.errors[name];
              });
            }
          });
        });
    },
    disabledSubmitButton() {
      let error = false;

      Object.values(this.form.inputs).every((element: any) => {
        if ((element.value === null && element.rule.includes('required')) || element.error !== null) {
          error = true;
          return false;
        }

        return true;
      });

      return error;
    },
    isCustomer() {
      return Object.keys(this.$store.state.selected.simulation).length && this.$store.state.selected.simulation.inputs.is_customer.value === 'yes';
    },
  },
});
